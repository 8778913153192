// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b3_kr";
export var caseStudyBackground__lineColor = "b3_kp";
export var caseStudyHead = "b3_kj";
export var caseStudyHead__imageWrapper = "b3_kk";
export var caseStudyProjectsSection = "b3_ks";
export var caseStudyQuote__bgLight = "b3_kt";
export var caseStudyQuote__bgRing = "b3_km";
export var caseStudyVideoReview = "b3_lK";
export var caseStudyVideoReview__bgRing = "b3_lL";
export var caseStudyVideo__ring = "b3_kv";
export var caseStudy__bgDark = "b3_kh";
export var caseStudy__bgLight = "b3_kg";
export var caseStudy__bgLightReverse = "b3_lJ";