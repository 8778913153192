import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import videoReviewLogo from '~/assets/images/case-study/impact/video-review-logo.svg?file'
import videoRingsImage from '~/assets/images/case-study/impact/video-review-rings.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Impact.module.scss'
import useImpactStaticQuery from './useImpactStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Richard Gunter',
    position: 'Founder of Impact Personal Training',
  },
  be: {
    name: 'Maksym',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewRefTitle = 'Dental Imaging Management System'
const previewBabelTitle = 'Insurance Progressive Web App'

const CaseStudiesImpact = () => {
  const query = useImpactStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const solutionDeliveredEight = getImage(
    fileToImageLikeData(query.solutionDeliveredEight),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Fitness Progressive Web Application for Impact"
          subtitle="Learn how Codica provided custom web app development for Impact Personal Training, connecting personal fitness trainers and their clients with a mobile- and user-friendly solution."
          maxWidthSubtitle="410px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Impact fitness training application | Codica"
          imageTitle="Fitness progressive web application | Codica"
          domain="Fitness"
          location="Luton, United Kingdom"
          timeline="June 2020 - April 2021"
          services={
            <>
              <Link to="/services/product-discovery/" key="productKey">
                Product discovery services
              </Link>
              , Prototyping,
              <Link
                to="/services/ui-ux-design/"
                className="ml5"
                key="designKey"
              >
                UX/UI design services
              </Link>
              , Front-end and Back-end development,
              <Link
                to="/services/progressive-web-apps-development/"
                className="ml5"
                key="pwaDevKey"
              >
                PWA development services
              </Link>
              ,
              <Link
                to="/services/mvp-development/"
                className="ml5"
                key="mvpDevKey"
              >
                MVP development services
              </Link>
            </>
          }
          team="1 Project Manager, 1 UX/UI Designer, 2 React Developers, 1 Ruby on Rails Developer, 1 QA Engineer"
          technologies="React, Ruby on Rails, Sidekiq, PostgreSql, DropBox, AWS, Gitlab"
        />
      </div>

      <div className={containerStyles.caseStudyVideoReview}>
        <LazyHydrate whenVisible>
          <CaseStudyClientVideoReview
            color="videoReviewImpact"
            link="https://www.youtube.com/embed/Ve1_FL9nIHw"
            clientImage={clientVideoReview}
            imageTitle="Customer review about fitness training application"
            logo={videoReviewLogo}
            name={[
              'Richard Gunter,',
              <span className="d-block" key="spanKey" />,
              'Founder of Impact Personal Training',
            ]}
            review={[
              {
                item: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. Nothing I asked for was too much.',
              },
              {
                item: 'I was always up to date with where they were at on the project itself, which I found really helpful. And you know, it’s the app itself that has improved our business.”',
              },
            ]}
            bgRings
            bgRingsClass={containerStyles.caseStudyVideoReview__bgRing}
            bgRingImage={videoRingsImage}
          />
        </LazyHydrate>
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTitleLeftList="Challenges"
        bgTextLeft={[
          'Impact Personal Training is a facility that helps clients train under personal fitness programs. These programs include individual exercise and nutrition plans developed by the Impact trainers.',
          <div className="mb-3" key="bgTextLeft" />,
          'During the COVID-19 pandemic, people needed to stay at home. So, the Impact company wanted to keep their clients training while they were not able to attend the gym.',
        ]}
        bgTextRight={[
          'The aim was to create a fitness app enabling clients to follow individual fitness programs that trainers would define for them. Such programs should include the exercises, nutrition and daily routines for clients so they see their sports results.',
          <div className="mb-3" key="bgTextLeft" />,
          'The fitness PWA should handle data on exercises, nutrition, and tracking fitness progress. At the same time, the application needed to be fast-loading, reliable, and stable.',
        ]}
        sectionBusiness={[
          {
            label:
              'Develop a fitness app to help clients keep fit while they stay at home during the COVID-19 pandemic.',
          },
          {
            label:
              'Deliver a reliable and stable application for both desktop and mobile devices.',
          },
          {
            label:
              'Optimize the database that includes a large number of exercises to reduce the server load.',
          },
          {
            label:
              'Create the system of assigning personal fitness programs and tracking the progress.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Created a fitness progressive web application from scratch based on the customer’s idea and requirements.',
          },
          {
            label:
              'Prepared desktop and mobile versions of the app to meet the needs of trainers and their clients. Both versions have appealing design and intuitive functionality.',
          },
          {
            label:
              'Optimized the set of features to make the PWA lightweight and fast-loading.',
          },
          {
            label: 'Created a stable app handling large volumes of data.',
          },
          {
            label: 'Improved the solution based on early feedback from users.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Sports, nutrition, and training are inspiring topics for me. I used several fitness applications. Also, I wanted to create a similar app myself. That’s why, I was very excited when Impact Personal Training turned to us with the need to make a fitness app.',
            },
            {
              text: 'The fitness app development was both challenging and engaging. As the app became popular, we needed to adapt it for high loads. So, we saw an opportunity for improving and enriching the initial version. The result of our custom web app development is a reliable solution that links personal trainers and their customers.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Max.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need an experienced team to build a custom web platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          isImageSmall
          image={solutionDeliveredOne}
          title="Cross-platform PWA"
          description="We created a high-speed and stable app for the clients and trainers of the fitness facility. The progressive web application for fitness can be accessed from the web browser on any device, and users don’t need to install it. Clients access their app from the homescreens of their smartphones, and trainers mostly use the desktop version."
          alt="Fitness cross-platform PWA for Impact | Codica"
          imageTitle="Impact fitness cross-platform PWA | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Exercises for individual fitness programs"
          description="We included the database with over 400 exercises for different parts of the body. The detailed explanation of each exercise helps clients to understand how it is to be performed and what benefits it has. Also, we developed the feature of adding sets and repetitions for each exercise."
          alt="Impact fitness programs | Codica"
          imageTitle="Exercises for Impact individual fitness programs | Codica"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Training management for clients"
          description="Thanks to the app, clients can get the recommended exercise set from personal trainers. The clients manage their individual programs with a convenient dashboard showing the training scale, today’s workout, and progress. Marking the exercises they completed, the clients track their achievements in the fitness app."
          alt="Impact fitness training management for clients | Codica"
          imageTitle="Fitness training management for clients | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Nutrition programs for clients"
          description="During the app development, a plan was created allowing clients to view nutrition programs received from their trainers. Clients tick the food eaten on their plan, as well as enter off-plan food consumption. The fitness PWA allows them to view nutrition plans for each day of the week."
          alt="Impact clients' nutrition programs | Codica"
          imageTitle="Impact nutrition programs for clients | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Metrics and metrics-based charts for clients"
          description={
            <>
              <Link
                to="/services/mobile-app-development/"
                className="mr5"
                key="linkMobileDev"
              >
                Our mobile app developers
              </Link>
              built a system that shows body measures metrics. It helps clients
              to track their progress in the training and nutrition programs.
              The metrics are relative to the goal set by a trainer. So, the
              clients see how far they have reached their goals.
            </>
          }
          alt="Impact fitness metrics for clients | Codica"
          imageTitle="Fitness metrics for clients by Impact | Codica"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Training management for trainers"
          description="We delivered a handy dashboard for personal trainers. The dashboard shows the list of the clients who need a training program. So, a trainer searches the database to select the most suitable exercises for each client. The fitness progressive web application allows adding several exercises or several sets to one exercise."
          alt="Impact fitness training management for trainers | Codica"
          imageTitle="Fitness training management for trainers | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgLightReverse}>
        <CaseStudySolution
          image={solutionDeliveredSeven}
          title="Nutrition programs management for trainers"
          description="The management system also enables a trainer to build nutrition programs depending on clients’ needs. Within the fitness PWA, trainers receive photos from clients showing the shape results that the latter achieved. Also, the trainer uses Impact functionality to track clients’ progress compared to the goals set individually for each client."
          alt="Impact trainers' nutrition programs management | Codica"
          imageTitle="Impact nutrition programs management for trainers | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredEight}
          title="Desktop and mobile UX/UI design"
          description={[
            'Together with Impact Personal Training, we created convenient interfaces for the gym clients and trainers. The mobile UX is tailored to the clients as they prefer using smartphones to enter their progress. Meanwhile, the trainers like the app desktop version to set fitness programs for the clients.',
            <div className="mb-2" key="descr" />,
            'We chose a dark theme as a highlight for the project UI. It resonates with the main color palette of the Impact sports gym. Also, it outlines determination and focus on progress. At the same time, we used Material Design elements, such as buttons and fields, customized for the Impact app look.',
          ]}
          alt="Impact UX/UI design for desktops and mobile devices | Codica"
          imageTitle="Impact desktop and mobile UX/UI design | Codica"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The video below shows you how the Impact app works. We adapted the solution for desktops and smartphones, for the trainers and their clients. The clear and coherent dashboards help trainers and clients compile and check individual fitness programs. The progressive web application for fitness also includes convenient metrics that help clients to track their progress."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/cATvtm1KsGU"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'React JS', icon: 'react' },
            { label: 'AWS', icon: 'aws' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'Sidekiq', icon: 'sidekiq' },
          ]}
          integrations={[
            { label: 'Gitlab', icon: 'gitlab' },
            { label: 'Sentry', icon: 'sentry' },
            { label: 'DropBox', icon: 'dropbox' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“The Codica team is professional and very helpful. They kept us informed on the project progress and promptly addressed all our questions and concerns.',
            },
            {
              text: 'When our trainers and their clients started using the first version of the app, they gave us feedback on what should be improved. Codica changed the solution and added new features per our requests, and ensured the proper work of the app even with high loads on servers.',
            },
            {
              text: 'As a result, Codica delivered an app that our clients and trainers love. We are very happy with our collaboration and strongly recommend Codica as a reliable web development team.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-impact.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewRefTitle}
              color="refColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-ref.png"
                  alt={`Case study: ${previewRefTitle} | Codica`}
                  title={`Case study: ${previewRefTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="dental-imaging-management-system"
            />
            <CaseStudyPreview
              title={previewBabelTitle}
              color="babelColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-babel.png"
                  alt={`Case study: ${previewBabelTitle} | Codica`}
                  title={`Case study: ${previewBabelTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="insurance-progressive-web-application"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesImpact
